import { navigate } from 'gatsby'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function CareerCard({
  cardName,
}: {
  cardName:
    | 'forensicscientist'
    | 'plantgeneticist'
    | 'geneticcounselor'
    | 'geneticengineer'
    | 'pharmacist'
}): JSX.Element {
  const goToCareer = (): void => {
    navigate(`/careers/${cardName}`)
  }

  const getImage = (): JSX.Element => {
    switch (cardName) {
      case 'forensicscientist':
        return (
          <StaticImage
            src="../../images/careers/ForensicScience.png"
            alt="Forensic Scientist"
            placeholder="blurred"
            aspectRatio={1 / 1}
          />
        )
      case 'geneticcounselor':
        return (
          <StaticImage
            src="../../images/careers/GeneticCounselor.png"
            alt="Genetic Counselor"
            placeholder="blurred"
            aspectRatio={1 / 1}
          />
        )
      case 'geneticengineer':
        return (
          <StaticImage
            src="../../images/careers/GeneticEngineering.png"
            alt="Genetic Engineer"
            placeholder="blurred"
            aspectRatio={1 / 1}
          />
        )
      case 'pharmacist':
        return (
          <StaticImage
            src="../../images/careers/Pipette.png"
            alt="Pharmacist"
            placeholder="blurred"
            aspectRatio={1 / 1}
          />
        )
      case 'plantgeneticist':
        return (
          <StaticImage
            src="../../images/careers/PlantGeneticist.png"
            alt="Plant Geneticist"
            placeholder="blurred"
            aspectRatio={1 / 1}
          />
        )
      default:
        return <></>
    }
  }

  const getText = (): JSX.Element => {
    switch (cardName) {
      case 'forensicscientist':
        return <h5>Forensic Scientist</h5>
      case 'geneticcounselor':
        return <h5>Genetic Counselor</h5>
      case 'geneticengineer':
        return <h5>Genetic Engineer</h5>
      case 'pharmacist':
        return <h5>Pharmacist</h5>
      case 'plantgeneticist':
        return <h5>Plant Geneticist</h5>
      default:
        return <></>
    }
  }

  return (
    <div
      className="aspectRatio"
      tabIndex={0}
      role="button"
      onKeyDown={goToCareer}
      onClick={goToCareer}
    >
      <div className="careerCard absoluteFill">
        <div className="careerImage">{getImage()}</div>
        <div className="careerBottom">{getText()}</div>
      </div>
    </div>
  )
}
