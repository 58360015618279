import * as React from 'react'

import Layout from '../components/layout'
import Banner from '../components/banner'
import CareerCard from '../components/cards/CareerCard'

export default function Career(): JSX.Element {
  return (
    <Layout>
      <div id="careers">
        <Banner bannerImage="Career" bannerText="Careers" textColor="black" />
        <h4 className="center coreRedText">
          At Youth Genes we hope to both empower and inspire students to specialize in professions
          revolving around genetics. Here we have a selection of different careers for those who are
          interested in pursuing a career in genetics.
        </h4>
        <h5 className="center">Click on a card below to learn more</h5>
        <div id="flex">
          <div className="wrapper grid">
            <aside className="aspectRatio" />
            <article>
              <div className="grid absoluteFill">
                <CareerCard cardName="forensicscientist" />
                <CareerCard cardName="plantgeneticist" />
                <CareerCard cardName="geneticcounselor" />
                <CareerCard cardName="geneticengineer" />
                <CareerCard cardName="pharmacist" />
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}
